import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { IUpload } from '../../../shared/types/types'
import type { RootState } from '../store'

interface AllUploadFileState {
	message: string
	loading: boolean
	error: string | null
}

const initialState: AllUploadFileState = {
	message: '',
	loading: false,
	error: null,
}

export const uploadFileThunk = createAsyncThunk(
	'uploadFile/uploadFileThunk',
	async (data: IUpload, { rejectWithValue }) => {
		try {
			const formData = new FormData()
			formData.append('file', data.File)
			formData.append('webPath', data.webPath)
			const response = await axios.post(
				`${process.env.REACT_APP_SERVER}/media/upload`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			)

			return response.data
		} catch (error: any) {
			alert(error.response.data.message)
			return rejectWithValue(error.message)
		}
	}
)

export const uploadFileSlice = createSlice({
	name: 'upload',
	initialState,
	reducers: {
		setCurrentUploadFile: (state, action) => {
			state.message = action.payload
		},
	},
	extraReducers: builder => {
		builder
			.addCase(uploadFileThunk.pending, state => {
				state.loading = true
				state.error = null
			})
			.addCase(
				uploadFileThunk.fulfilled,
				(state, action: PayloadAction<any>) => {
					state.loading = false
					state.message = action.payload
				}
			)
			.addCase(
				uploadFileThunk.rejected,
				(state, action: PayloadAction<any>) => {
					state.loading = false
					state.error = action.payload
				}
			)
	},
})
export const { setCurrentUploadFile } = uploadFileSlice.actions

export const uploadFileData = (state: RootState) =>
	state.uploadFileSlice.message
export const uploadFileLoading = (state: RootState) =>
	state.uploadFileSlice.loading
export const uploadFileError = (state: RootState) => state.uploadFileSlice.error

export default uploadFileSlice.reducer
