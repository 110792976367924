import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createFolderSeqActionSlice } from '../../app/redux/slices/createFolderSeqActionSlice'
import imgPlus from '../../shared/assets/icons/Add.svg'
import { IButtonAdd } from '../../shared/types/types'
import Modal from '../../shared/ui/Modal/Modal'
import style from './ButtonAdd.module.scss'

const ButtonAdd: React.FC<IButtonAdd> = ({ arg }) => {
	const [isOpen, setIsOpen] = useState(false)
	const dispatchThunk = useDispatch<ThunkDispatch<{}, {}, AnyAction>>()
	const modalContainerRef = useRef<HTMLDivElement>(null)
	const PathOne = useSelector((state: any) => state.CurrentDirectorySlice)
	const handleOpen = () => {
		setIsOpen(true)
	}

	const handleClose = () => {
		setIsOpen(false)
	}

	const helloFunc = (nameFile: string) => {
		dispatchThunk(
			createFolderSeqActionSlice({ path: arg.join('/'), folderName: nameFile })
		)
		handleClose()
	}

	// Закрытие при клике вне модального окна и при нажатии Esc
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				modalContainerRef.current &&
				!modalContainerRef.current.contains(event.target as Node)
			) {
				handleClose()
			}
		}

		const handleEscPress = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				handleClose()
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		document.addEventListener('keydown', handleEscPress)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
			document.removeEventListener('keydown', handleEscPress)
		}
	}, [])

	return (
		<>
			<button className={style.wrapperButtonAdd} onClick={handleOpen}>
				<img src={imgPlus} alt='+' />
				Добавить
			</button>
			{isOpen && (
				<div className={style.modalBackdrop}>
					<div ref={modalContainerRef} className={style.wrapperModalWin}>
						<Modal
							title='Новая папка'
							arg='Добавить'
							valid={PathOne.path.join('/').split('/').pop()}
							isOpen={isOpen}
							callback={helloFunc}
							onCancel={handleClose} // Передаем функцию handleClose для кнопки Отмена
						/>
					</div>
				</div>
			)}
		</>
	)
}

export default ButtonAdd
