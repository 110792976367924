import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentFolder } from '../../app/redux/slices/currentFolderInfo'
import { setCurrentImagePath } from '../../app/redux/slices/CurrentImageSlice'
// import { fetchFolderNewCurrent } from '../../app/redux/slices/currentNewFolderInfo'
import {
	selectPathNew,
	setCurrentPathNew,
} from '../../app/redux/slices/CurrentDirectoryNewSlice'
import { setCurrentPath } from '../../app/redux/slices/CurrentDirectorySlice'
import { setDropDownData } from '../../app/redux/slices/DropDownSlice'
import { fetchDirectories } from '../../app/redux/slices/getAllDirectories'
import {
	GoBackCount,
	setCount,
} from '../../app/redux/slices/GoBack/GoBackCountSlice'
import { performSequentialActions } from '../../app/redux/slices/performSequentialActionSlice'
import {
	fetchFolderNewLeftThunk,
	setPrevDataLeft,
} from '../../app/redux/slices/prevDataLeft/prevDataLeftSlice'
import { setCurrentPrevRightData } from '../../app/redux/slices/PrevRightDataSlice'
import { setSaverFolderData } from '../../app/redux/slices/SaveFolderDataSlice'
import { setSelectCurrentFirst } from '../../app/redux/slices/stateSelect/FirstSelectedPathSlice'
import {
	currentSelectState,
	setSelectCurrent,
} from '../../app/redux/slices/stateSelect/stateSelectSlice'
import { AppDispatch } from '../../app/redux/store'
import { BuildPathLeft } from '../../features/BuildPathLeft'
import getFileExtension from '../../features/FindExten'
import { FindToDeletePath } from '../../features/FindToDeletePath'
import splitText from '../../features/splitText'
import { arrIcons } from '../../shared/IconsAndExtensio'
import { FolderFace, FolderFaceNew } from '../../shared/types/types'
import { ContextMenuV2 } from '../ContextMenuV2/ContextMenuV2'
import style from './BottomContextV2.module.scss'
const BottomContextLeftV2: React.FC<FolderFaceNew> = ({
	name,
	type,
	path,
	isHaveContent,
	contents,
	index,
	size,
}) => {
	const dispatch = useDispatch<AppDispatch>()
	const dispatchThunk = useDispatch<ThunkDispatch<{}, {}, AnyAction>>()
	// const { currentFolderInfo, loadingInfo, errorInfo } = useSelector(
	// 	(state: any) => state.currentFolder
	// )
	const currentFolderInfo = useSelector(
		(state: any) => state.prevDataLeftSlice.prevDataLeft
	)
	const selectPathNewLocal = useSelector(selectPathNew)
	//setPrevDataLeft
	const [contextMenuPath, setContextMenuPath] = useState<string | null>(null)
	const { currentInfoAboutMove, loadingInfoMove, errorInfoMove } = useSelector(
		(state: any) => state.fetchMoveSlice
	)

	const PathOne = useSelector((state: any) => state.CurrentDirectoryNewSlice)
	const [isContextMenu, setIsContextMenu] = useState<any>(false)
	const [idContextMenu, setIdContextMenu] = useState<any>(null)
	const [isExpanded, setIsExpanded] = useState(false)
	const [draggedItem, setDraggedItem] = useState<any>(null)
	const refBottomContext = useRef<any>(null)
	const currentSelectStateLocal = useSelector(currentSelectState)
	const [click, setClick] = useState<number>(0)
	const [select, setSelect] = useState(false)
	const GoBackCountLocal = useSelector(GoBackCount)
	function removeLastSegment(path: string): string {
		const lastSlashIndex = path.lastIndexOf('/')

		// Если слэш не найден, возвращаем исходную строку
		if (lastSlashIndex === -1) {
			return path
		}

		return path.slice(0, lastSlashIndex)
	}

	const handleFolderClick = () => {
		if (name === '...') {
			var extreme: any
			if (PathOne.path.length === 1 && PathOne[0] !== '/') {
				dispatch(setCurrentPathNew('/'))
				dispatch(fetchFolderNewLeftThunk('/'))
				dispatch(setCurrentPathNew('/'))

				dispatch(setDropDownData(contents))
				extreme = '/'
			} else if (PathOne.path.length > 1 && PathOne[0] !== '/') {
				dispatch(setCurrentPathNew(PathOne.path.slice(0, -1).join('/')))
				extreme = PathOne.path.slice(0, -1)
				dispatch(fetchFolderNewLeftThunk(extreme.join('/')))
				dispatch(setCurrentPathNew(extreme.join('/')))
				dispatch(setDropDownData(contents))
			}
			return
		}
		dispatch(setCurrentImagePath(path))
		if (contents && contents.length > 0) {
			dispatch(setSelectCurrentFirst(path))
			let some: any = currentFolderInfo
				? BuildPathLeft(name, currentFolderInfo)
				: null
			dispatch(setPrevDataLeft(currentFolderInfo))
			dispatch(setCurrentFolder(contents))
			dispatch(fetchDirectories())
			dispatch(setDropDownData(contents))

			dispatch(setCurrentPathNew(path))
			dispatch(setCurrentPath(removeLastSegment(path)))
		} else if (contents?.length === 0 && type === 'directory') {
			let some: any = currentFolderInfo
				? BuildPathLeft(name, currentFolderInfo)
				: null
			dispatch(setCurrentPathNew(path))
			dispatch(setCurrentPath(some))
			dispatch(fetchDirectories())
			dispatch(setCurrentFolder([]))
		}
		dispatch(setCurrentPrevRightData(contents))
	}
	useEffect(() => {
		if (PathOne.path.length === 0) {
			dispatch(setPrevDataLeft(null))
			dispatch(setCurrentPath('/'))
			dispatch(setCount(0))
		} else if (PathOne.path.length !== 0 && GoBackCountLocal > 0) {
			dispatch(fetchFolderNewLeftThunk(PathOne.path.join('/')))
		}
	}, [PathOne])

	const handleExpandToggle = () => {
		setIsExpanded(!isExpanded)
	}
	const [extenState, setExtenState] = useState('')
	const [pathSrc, setPathSrc] = useState('')
	useEffect(() => {
		let extenOne: null | string = null
		let typeOne: null | 'directory' = null
		const exten: string = getFileExtension(name)
		if (exten.trim().length === 0) {
			extenOne = null
			setExtenState('Все')
		} else if (exten.trim().length > 0) {
			extenOne = exten
			setExtenState(exten)
		}
		if (type === undefined) {
			typeOne = null
		} else if (type === 'directory') {
			typeOne = 'directory'
			setExtenState('Папка')
		}

		const result: string = arrIcons(extenOne, typeOne)
		setPathSrc(result)
	}, [])

	const handleContextMenu = (event: React.MouseEvent) => {
		const elementId = (event.currentTarget as HTMLElement).id

		// Если id отличается от текущего или меню закрыто, обновляем его состояние
		if (elementId !== idContextMenu) {
			setIdContextMenu(elementId)
			setIsContextMenu(true)
		} else {
			// Закрываем меню, если кликнули по тому же элементу
			setIsContextMenu(false)
			setIdContextMenu(null)
		}
	}

	const handlePath = () => {
		let result: any = currentFolderInfo
			? BuildPathLeft(name, currentFolderInfo)
			: null
		let arg: string | null = currentFolderInfo
			? FindToDeletePath({ name, data: currentFolderInfo })
			: null
		setContextMenuPath(result)
	}

	const handleDragStart = (event: React.DragEvent, item: any) => {
		setDraggedItem(item)
		event.dataTransfer.setData('text/plain', JSON.stringify(item))
	}

	const handleDragOver = (event: React.DragEvent) => {
		event.preventDefault()
	}

	function trimLastSegment(str: string): string {
		const lastSlashIndex = str.lastIndexOf('/')
		// Если в строке нет '/' или она содержит только одно слово, возвращаем пустую строку
		return lastSlashIndex === -1 ? '' : str.substring(0, lastSlashIndex)
	}

	function trimLastTwoSegments(str: string): string {
		// Если в строке нет '/', значит это одно слово, возвращаем пустую строку
		if (!str.includes('/')) return ''

		const lastSlashIndex = str.lastIndexOf('/')
		const secondLastSlashIndex = str.lastIndexOf('/', lastSlashIndex - 1)

		return secondLastSlashIndex !== -1
			? str.substring(0, secondLastSlashIndex)
			: ''
	}

	const handleDrop = (event: React.DragEvent, destination: any) => {
		event.preventDefault()

		//если тип не директория то загружаем на всю папку открытую и берем путьс с pathone
		const droppedItem = JSON.parse(event.dataTransfer.getData('text/plain'))

		if (destination.type === 'directory') {
			let newPath = destination.path + '/' + droppedItem.name
			let oldPath = droppedItem.path

			if (newPath === oldPath) {
				return
			}
			let resultPath = trimLastSegment(droppedItem.path)

			dispatchThunk(
				performSequentialActions({
					newPath: destination.path,
					oldPath: droppedItem.path,
					PathOne,
					leftPath: trimLastSegment(PathOne.path.join('/')),
				})
			)
		}
		if (
			PathOne.path &&
			PathOne.path.length !== 0 &&
			PathOne.path[0].trim().length !== 0 &&
			destination.name === '...'
		) {
			let resultPath = trimLastTwoSegments(droppedItem.path)

			dispatchThunk(
				performSequentialActions({
					newPath: resultPath,
					oldPath: droppedItem.path,
					PathOne,
					leftPath: resultPath,
				})
			)
		}
		let dropInFolder: string = destination.name

		if (destination.type !== 'directory') {
			let resultPath = trimLastTwoSegments(droppedItem.path)
			dispatchThunk(
				performSequentialActions({
					newPath: resultPath,
					oldPath: droppedItem.path,
					PathOne,
					leftPath: resultPath,
				})
			)
		}
	}

	useEffect(() => {
		if (isContextMenu) {
			handlePath()
		}
	}, [isContextMenu])
	const handleCoutContext = () => {
		setIsContextMenu(false)
		setSelect(!select)
		dispatch(setSelectCurrent(name))
		dispatch(setCurrentImagePath(path))

		dispatch(
			setSaverFolderData({
				name,
				type,
				path,
				isHaveContent,
				contents,
				index,
				size,
			})
		)
		dispatch(
			setCurrentPrevRightData({
				name,
				type,
				size,
				isLeft: true,
			})
		)
	}

	const [dataOpenState, setDataOpenState] = useState<FolderFace | null>(null)
	useEffect(() => {
		setDataOpenState({
			name,
			type,
			path,
			isHaveContent,
			contents,
			size,
		})
	}, [path])
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				refBottomContext.current &&
				!refBottomContext.current.contains(event.target as Node)
			) {
				setIsContextMenu(false)
				setIdContextMenu(null)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [refBottomContext])
	return (
		<div className={style.wrapperList}>
			<div
				id={name}
				onClick={handleCoutContext}
				onDoubleClick={() => {
					setSelect(!select)
					dispatch(setSelectCurrent(name))
				}}
				ref={refBottomContext}
				onContextMenu={handleContextMenu}
				className={
					currentSelectStateLocal === name
						? style.wrapperFolderSelect
						: style.wrapperFolder
				}
				draggable
				onDragStart={e =>
					handleDragStart(e, { name, type, path, isHaveContent, contents })
				}
				onDragOver={handleDragOver}
				onDrop={e =>
					handleDrop(e, { name, type, path, isHaveContent, contents })
				}
			>
				<div
					id={path}
					className={style.folderHeader}
					onDoubleClick={handleFolderClick}
				>
					<img
						className={style.iconFiles}
						id='inner'
						src={pathSrc}
						alt='folder'
					/>
					<span id={path} className={style.elementName}>
						{splitText(name, 15)}
					</span>
				</div>
				{type === 'directory' && isExpanded && contents && (
					<div id={path} className={style.contents}>
						{contents.map((element: any) => {
							return <BottomContextLeftV2 {...element} />
						})}
					</div>
				)}
				{isContextMenu && (
					<ContextMenuV2 isContext={isContextMenu} path={path} />
				)}
			</div>
		</div>
	)
}

export default BottomContextLeftV2
