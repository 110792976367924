import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setContextMenuData } from '../../app/redux/slices/ContextMenuSlice/ContextMenuSlice'
import { createFileSeqActionSlice } from '../../app/redux/slices/createFileSeqActionSlice'
import { createFolderSeqActionSlice } from '../../app/redux/slices/createFolderSeqActionSlice'
import { deleteFolderSeqActionSlice } from '../../app/redux/slices/deleteFolderSeqActionSlice'
import { renameFileSeqActionSlice } from '../../app/redux/slices/renameFileSeqActionSlice'
import { UploadFileSequentialActions } from '../../app/redux/slices/UploadFileSequentialActionSlice'
import {
	uploadFileData,
	uploadFileError,
} from '../../app/redux/slices/UploadFilesSlice'
import { AppDispatch } from '../../app/redux/store'
import imgCreateFolder from '../../shared/assets/icons/AddFolder.svg'
import imgAddFile from '../../shared/assets/icons/File Send.svg'
import style from './ContextMenuV2.module.scss'
interface ContextMenuProps {
	path: string | null
	isContext: boolean
}
export const ContextMenuV2: React.FC<ContextMenuProps> = ({
	path,
	isContext,
}) => {
	const dispatch = useDispatch<AppDispatch>()
	const PathDirectory = useSelector((state: any) => state.CurrentDirectorySlice)

	const dispatchThunk = useDispatch<ThunkDispatch<{}, {}, AnyAction>>()
	const uploadFileDataLocal = useSelector(uploadFileData)

	const uploadFileErrorLocal = useSelector(uploadFileError)
	const handleDelete = (path: string | null) => {
		const pathOne: string = String(path)
		dispatchThunk(
			deleteFolderSeqActionSlice({
				path: pathOne,
				pathFolder: PathDirectory.path.join('/'),
			})
		)
	}
	const handlerFirstFolder = () => {
		let name: string | null = prompt('Введите название папки, пожалуйста')

		// Проверка на null или пустую строку
		if (name === null || name.trim() === '') {
			alert('Создание папки отменено или введено пустое название.')
			return
		}

		handleAddFolder(path, name)
	}

	const handlerCreateFileFirst = () => {
		let name: string | null = prompt('Введите название файла, пожалуйста')

		// Проверка на null или пустую строку для имени файла
		if (name === null || name.trim() === '') {
			alert('Создание файла отменено или введено пустое название.')
			return
		}

		let content: string | null = prompt('Введите контент, пожалуйста')

		// Проверка на null или пустую строку для контента
		if (content === null || content.trim() === '') {
			alert('Создание файла отменено из-за пустого контента.')
			return
		}
		if (path) {
			handleAddFile(path, name, content)
		}
	}

	const handleAddFolder = (path: string | null, nameFile: string) => {
		dispatchThunk(
			createFolderSeqActionSlice({
				path: path + '',
				folderName: nameFile,
			})
		)
	}
	const [selectedFile, setSelectedFile] = useState<File | null>(null)
	const [uploadStatus, setUploadStatus] = useState<string>('')

	// Обработчик выбора файла
	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files.length > 0) {
			setSelectedFile(event.target.files[0])
		}
	}
	const handleUploadFile = (path: string, file: any, webPath: string) => {}
	const handleAddFile = (path: string, nameFile: string, content: string) => {
		dispatchThunk(
			createFileSeqActionSlice({
				path,
				fileName: nameFile,
				content,
			})
		)
	}

	const firstRenameFile = () => {
		let newName: string | null = prompt('Введите новое название, пожалуйста')

		// Проверка на null
		if (newName === null || newName === '') {
			// Пользователь нажал "Cancel", ничего не делаем
			return
		}

		// Проверка, если пользователь не ввел ничего (пустая строка)
		if (newName.trim() === '') {
			alert('Название не может быть пустым.')
			return
		}

		// Если все в порядке, вызываем dispatch с новым именем файла
		dispatchThunk(
			renameFileSeqActionSlice({
				path,
				newName,
				pathFolder: PathDirectory.path.join('/'),
			})
		)
	}
	const [FileState, setFileState] = useState<File | null>(null)
	//const dispatch = useDispatch<AppDispatch>()
	// Обработчик выбора файла и его загрузки
	const handleFileChange2 = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		event.preventDefault()

		try {
			const File = event.target.files?.[0]

			if (File) {
				setFileState(File)
				// Обработка файла
			}
		} catch (error) {
			console.error('Error while selecting file:', error)
		}
	}

	useEffect(() => {
		if (FileState && path) {
			dispatchThunk(
				UploadFileSequentialActions({ File: FileState, webPath: path })
			)
		}
	}, [FileState])
	const handleOpenFileInput = () => {
		document.getElementById('image-loader')?.click()
	}

	return (
		<div className={style.wrapperContextMenu}>
			<ul className={style.wrapperMenuItem}>
				<input
					id='file-upload'
					type='file'
					onChange={handleFileChange}
					style={{ display: 'none' }} // Скрываем input
				/>
				{/* <li className={style.menuItem}>
					<button
						className={style.btnMenuItem}
						onClick={() => handleDelete(path)}
					>
						Удалить
					</button>
				</li> */}
				<li className={style.menuItem}>
					<button
						className={style.btnMenuItem}
						onClick={() =>
							dispatch(
								setContextMenuData({
									isCreate: true,
									isUploadFile: false,
									isUploadDirectory: false,
								})
							)
						}
					>
						<img src={imgCreateFolder} alt='' />
						Создать папку
					</button>
				</li>
				{/* <li className={style.menuItem}>
					<button
						className={style.btnMenuItem}
						// onClick={() => handlerCreateFileFirst()}
					>
						<img src={imgAddFile} alt="" />
						Загрузить файл
					</button>
				</li> */}
				<li className={style.menuItem}>
					<button
						className={style.btnMenuItem}
						onClick={() =>
							dispatch(
								setContextMenuData({
									isCreate: false,
									isUploadFile: true,
									isUploadDirectory: false,
								})
							)
						}
					>
						<img src={imgAddFile} alt='' />
						Загрузить файл
						<input
							className={style.wrapperInput}
							id='image-loader'
							type='file'
							onChange={handleFileChange2}
							onClick={e => e.stopPropagation()} // предотвращаем закрытие меню
						/>
					</button>
				</li>
				{/* <li className={style.menuItem}>
					<button
						className={style.btnMenuItem}
						onClick={() =>
							dispatch(
								setContextMenuData({
									isCreate: false,
									isUploadFile: false,
									isUploadDirectory: true,
								})
							)
						}
					>
						<img src={imgAddFolder} alt='' />
						Загрузить папку
					</button>
				</li> */}
			</ul>
		</div>
	)
}
