import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

interface CurrentDirectoryAndPath {
	path: string[]
}

const initialState: CurrentDirectoryAndPath = {
	path: ['/'], // Начальное состояние: корневой путь
}

export const CurrentDirectorySlice = createSlice({
	name: 'path',
	initialState,
	reducers: {
		setCurrentPath: (state, action: PayloadAction<string>) => {
			state.path = action.payload.split('/').filter(element => element !== '')
		},
		setDeleteLastPath: state => {
			if (state.path.length === 1 && state.path[0] !== '/') {
				state.path = ['/']
			} else if (state.path.length > 1 && state.path[0] !== '/') {
				state.path = state.path.slice(0, -1)
			}
		},
	},
})

export const { setCurrentPath, setDeleteLastPath } =
	CurrentDirectorySlice.actions

export const selectPath = (state: RootState) => state.CurrentDirectorySlice.path

export default CurrentDirectorySlice.reducer
