import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchGenerateLink } from '../../app/redux/slices/generateLinkSlice'
import { setOpenModalId } from '../../app/redux/slices/ModalSlice'
import { renameFileSeqActionSlice } from '../../app/redux/slices/renameFileSeqActionSlice'

import {
	currentFirstSelectedPath,
	currentFirstSelectNameFullPath,
} from '../../app/redux/slices/stateSelect/FirstSelectedPathSlice'

import { deleteFolderSeqActionSlice } from '../../app/redux/slices/deleteFolderSeqActionSlice'
import { AppDispatch } from '../../app/redux/store'
import { downloadFile, downloadZip } from '../../features/SaveFile'
import imgOption from '../../shared/assets/icons/optionVertical.svg'
import { IOption } from '../../shared/types/types'
import Modal from '../../shared/ui/Modal/Modal'
import ModalOption from '../../shared/ui/ModalOption/ModalOption'
import style from './ButtonOption.module.scss'

const ButtonOption: React.FC<IOption> = ({ arg, folder }) => {
	const [openModal, setOpneModal] = useState(false)
	const dispatchThunk = useDispatch<ThunkDispatch<{}, {}, AnyAction>>()
	const { generateLink } = useSelector((state: any) => state.generateLinkSlice)
	const [isOneFile, setIsOneFile] = useState<boolean>(false)
	const dispatch = useDispatch<AppDispatch>()
	const [isOpenRename, setIsOpenRename] = useState<boolean>(false)
	const PathDirectory = useSelector((state: any) => state.CurrentDirectorySlice)
	//const [isOpen, setIsOpen] = useState(false);
	const openModalId = useSelector((state: any) => state.ModalSlice.openModalId)
	const modalContainerRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (generateLink && folder.type !== 'directory' && isOneFile) {
			downloadFile(generateLink.link)
			setIsOneFile(false)
		}
	}, [generateLink, isOneFile])
	const isOpen = openModalId === folder.path // Проверка, открыт ли модал для этого компонента

	const handleOpenModal = () => {
		// Переключение состояния: закрыть, если открыт, или открыть, если закрыт
		dispatch(setOpenModalId(isOpen ? null : folder.path))
	}
	const firstRenameFile = (newName: string) => {
		//let newName: string | null = prompt('Введите новое название, пожалуйста')

		// Проверка на null
		if (newName === null || newName === '') {
			// Пользователь нажал "Cancel", ничего не делаем
			return
		}

		// Проверка, если пользователь не ввел ничего (пустая строка)
		if (newName && newName.trim() === '') {
			alert('Название не может быть пустым!')
			return
		}

		// Если все в порядке, вызываем dispatch с новым именем файла
		dispatchThunk(
			renameFileSeqActionSlice({
				path: folder.path,
				newName,
				pathFolder: PathDirectory.path.join('/'),
			})
		)
	}
	// Закрытие при клике вне модального окна и при нажатии Esc
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				modalContainerRef.current &&
				!modalContainerRef.current.contains(event.target as Node)
			) {
				setIsOpenRename(false)
			}
		}

		const handleEscPress = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				setIsOpenRename(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		document.addEventListener('keydown', handleEscPress)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
			document.removeEventListener('keydown', handleEscPress)
		}
	}, [])

	const currentFirstSelectedPathLocal = useSelector(currentFirstSelectedPath)
	const currentFirstSelectNameFullPathLocal = useSelector(
		currentFirstSelectNameFullPath
	)

	return (
		<div>
			<button className={style.wrapperOption} onClick={handleOpenModal}>
				<img src={imgOption} className={style.imgOption} alt='' />
			</button>
			<div
				className={isOpen ? style.wrapperOptionWin : style.wrapperOptionHide}
			>
				<ModalOption
					option={[
						{
							name: 'Скачать',
							optionFunc: () => {
								if (folder.type !== 'directory') {
									dispatch(fetchGenerateLink(arg))
									setIsOneFile(true)
									dispatch(setOpenModalId(null)) // Закрытие модального окна
								} else if (folder.type === 'directory') {
									downloadZip(
										folder,
										currentFirstSelectNameFullPathLocal,
										currentFirstSelectedPathLocal
									)
									dispatch(setOpenModalId(null)) // Закрытие модального окна
								}
							},
						},
						{
							name: 'Переименовать',
							optionFunc: () => {
								//firstRenameFile()
								setIsOpenRename(true)
							},
						},
						{
							name: 'Отправить в корзину',
							optionFunc: () => {
								const pathOne: string = String(folder.path)
								dispatchThunk(
									deleteFolderSeqActionSlice({
										path: pathOne,
										pathFolder: PathDirectory.path.join('/'),
									})
								)
							},
						},
					]}
				/>
			</div>
			{isOpenRename && (
				<div ref={modalContainerRef} className={style.wrapperModalWin}>
					<Modal
						title='Переименовать'
						arg='Переименовать'
						isOpen={isOpenRename}
						callback={firstRenameFile}
						valid={folder.path.split('/').pop()}
						onCancel={() => setIsOpenRename(false)} // Передаем функцию handleClose для кнопки Отмена
					/>
				</div>
			)}
		</div>
	)
}

export default ButtonOption
