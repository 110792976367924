import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios from 'axios'
import { moveProps } from '../../../shared/types/types'

  interface pathMoveDocSlice {
    currentInfoAboutMove: string,
    loadingInfoMove: boolean;
    errorInfoMove: string | null;
  }
  

  const initialState: pathMoveDocSlice = {
    currentInfoAboutMove: "",
    loadingInfoMove: false,
    errorInfoMove: null,
  };

  
  export const fetchMove = createAsyncThunk(
    'directoryOne/moveOneObject',
    async (arg:moveProps, { rejectWithValue }) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_SERVER}/media/move`, {...arg});
        return response.data; // Assuming this returns Directory[]
      } catch (errorInfoMove: any) {
        return rejectWithValue(errorInfoMove.message);
      }
    }
  );
  
  export const fetchMoveSlice = createSlice({
    name: 'pathDelete',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchMove.pending, (state) => {
          state.loadingInfoMove = true;
          state.errorInfoMove = null;
        })
        .addCase(fetchMove.fulfilled, (state, action: PayloadAction<any>) => {
          state.loadingInfoMove = false;
          state.currentInfoAboutMove = action.payload;
        })
        .addCase(fetchMove.rejected, (state, action: PayloadAction<any>) => {
          state.loadingInfoMove = false;
          state.errorInfoMove = action.payload;
        });
    },
  });

  
  export default fetchMoveSlice.reducer;
  