import { useDispatch, useSelector } from 'react-redux'
import { isListData, setIsList } from '../../app/redux/slices/IsListSlice'
import { AppDispatch } from '../../app/redux/store'
import imgMenu from '../../shared/assets/icons/HamburgerMenu.svg'
import imgBlockMenu from '../../shared/assets/icons/WidgetMenu.svg'
import { IView } from '../../shared/types/types'
import style from './ButtonView.module.scss'

const ButtonView: React.FC<IView> = ({ view }) => {
	const isListDataLocal = useSelector(isListData)
	//islist редакс дата
	const dispatch = useDispatch<AppDispatch>()

	const handleChangeView = () => {
		dispatch(setIsList(!isListDataLocal))
	}
	return (
		<button className={style.wrapperHamburgerMenu} onClick={handleChangeView}>
			<img
				src={imgMenu}
				className={isListDataLocal ? style.imgBlockMenu : style.imgMenu}
				alt='imgMenu'
			/>
			<img
				src={imgBlockMenu}
				className={isListDataLocal ? style.imgMenu : style.imgBlockMenu}
				alt='imgBlockMenu'
			/>
		</button>
	)
}

export default ButtonView
