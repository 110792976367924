import { DropDownTypes } from '../shared/types/types'
const types: DropDownTypes[] = [
	{ name: 'Все', value: 'all' },
	{ name: 'Папки', value: 'directory' },
	{ name: 'JPG', value: '.jpg' },
	{ name: 'MP4', value: '.mp4' },
	{ name: 'TXT', value: '.txt' },
	{ name: 'WEBP', value: '.webp' },
	{ name: 'PNG', value: '.png' },
]
export const findExtenInExtens = (value: string): string => {
	let needExten = ''

	types.some((element: DropDownTypes) => {
		if (element.value === value) {
			needExten = element.name
			return true
		}
		needExten = 'Все'
		return false
	})

	return needExten
}
