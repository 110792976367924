import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface InputProps {
	inputImage: string | null
}

const initialState: InputProps = {
	inputImage: '', // Начальное состояние: корневой путь
}

export const CurrentInputSlice = createSlice({
	name: 'input',
	initialState,
	reducers: {
		setCurrentInput: (state, action: PayloadAction<string | null>) => {
			state.inputImage = action.payload
		},
	},
})

export const { setCurrentInput } = CurrentInputSlice.actions

export default CurrentInputSlice.reducer
