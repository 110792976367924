import React, { useEffect, useState } from 'react'
import ArrowRight from '../../shared/assets/icons/ArrowRight.svg'
import option from '../../shared/assets/icons/option.svg'
import style from './PathV2.module.scss'
import splitText from '../../features/splitText'

interface PathProps {
	path: string[]
}

interface IStatePath {
	ThreePath: 'first' | 'second' | 'third'
}

const PathV2: React.FC<PathProps> = ({ path }) => {
	const [statePath, setStatePath] = useState<IStatePath>({ ThreePath: 'first' })
	const [stringPath, setStringPath] = useState('')

	useEffect(() => {
		if (path.length === 1) {
			setStringPath(path[0])
			setStatePath({ ThreePath: 'first' })
		} else if (path.length > 1) {
			
			setStringPath(path[path.length - 1])
			setStatePath({ ThreePath: 'second' })
		} else if (path.length === 0) {
			setStringPath('')
			setStatePath({ ThreePath: 'third' })
		}
	}, [path])

	
	return (
		<div className={style.wrapperPath}>
			{statePath.ThreePath === 'first' && (
				<div className={style.contentPath}>
					<span>Все файлы</span>
					<img src={ArrowRight} alt='' />
					<span>{splitText(stringPath, 18)}</span>
				</div>
			)}
			{statePath.ThreePath === 'second' && (
				<div className={style.contentPath}>
					<img src={option} alt='' />
					<img src={ArrowRight} alt='' />
					<span>{splitText(stringPath, 18)}</span>
				</div>
			)}
			{statePath.ThreePath === 'third' && (
				<div className={style.contentPath}>
					<span>Все файлы</span>
				</div>
			)}
		</div>
	)
}

export default PathV2
