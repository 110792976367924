import {  createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../store";
//import { RootState } from '../store'

  interface IStateSelect {
    name: string; 

  }
  const initialState: IStateSelect = {
        name: ""

  };
  
 
  
  export const setSelectSlice = createSlice({
    name: 'dataState',
    initialState,
    reducers: {
      setSelectCurrent: (state, action) => {
        state.name = action.payload === state.name? "":action.payload;
      },
    },
  });
  
  export const currentSelectState = (state: RootState) => state.setSelectSlice.name;
  export const {setSelectCurrent} = setSelectSlice.actions
  
  export default setSelectSlice.reducer;
  