import { configureStore } from '@reduxjs/toolkit'
import directoryReducer from './slices/getAllDirectories'

import folderDelete from './slices/AsyncDeleteDocSlice'
import ContextMenuSlice from './slices/ContextMenuSlice/ContextMenuSlice'
import folderCreateSlice from './slices/CreateFolderSlice'
import CurrentDirectoryNewSlice from './slices/CurrentDirectoryNewSlice'
import CurrentDirectorySlice from './slices/CurrentDirectorySlice'
import currentFolder from './slices/currentFolderInfo'
import CurrentImageSlice from './slices/CurrentImageSlice'
import CurrentInputSlice from './slices/CurrentInputSlice'
import dropDownSlice from './slices/DropDownSlice'
import DropDownV2StateSlice from './slices/DropDownV2StateSlice'
import generateLinkSlice from './slices/generateLinkSlice'
import directoryUnderSlice from './slices/getAllDirectoriesUnder'
import globalRefFolderSlice from './slices/globalRefFolderSlice'
import GoBackCountSlice from './slices/GoBack/GoBackCountSlice'
import IsListSlice from './slices/IsListSlice'
import IsOpenLeftSlice from './slices/IsOpenLeftSlice'
import IsOpenSlice from './slices/IsOpenSlice'
import isVoidSlice from './slices/isVoid/isVoidSlice'
import ModalSlice from './slices/ModalSlice'
import fetchMoveSlice from './slices/moveFolderSlice'
import prevDataLeftSlice from './slices/prevDataLeft/prevDataLeftSlice'
import PrevRightDataSlice from './slices/PrevRightDataSlice'
import renameFileSlice from './slices/renameFileSlice'
import SaveFolderDataSlice from './slices/SaveFolderDataSlice'
import FirstSelectedPathSlice from './slices/stateSelect/FirstSelectedPathSlice'
import setSelectSlice from './slices/stateSelect/stateSelectSlice'
import uploadFileSlice from './slices/UploadFilesSlice'

const store = configureStore({
	reducer: {
		directory: directoryReducer,
		currentFolder,
		CurrentDirectorySlice,
		folderDelete,
		fetchMoveSlice,
		directoryUnderSlice,
		globalRefFolderSlice,
		dropDownSlice,
		CurrentImageSlice,
		generateLinkSlice,
		folderCreateSlice,
		renameFileSlice,
		uploadFileSlice,
		CurrentInputSlice,
		DropDownV2StateSlice,
		IsListSlice,
		ModalSlice,
		IsOpenSlice,
		IsOpenLeftSlice,
		PrevRightDataSlice,
		SaveFolderDataSlice,
		setSelectSlice,
		prevDataLeftSlice,
		CurrentDirectoryNewSlice,
		FirstSelectedPathSlice,
		GoBackCountSlice,
		isVoidSlice,
		ContextMenuSlice,
	},
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
