import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

interface ICurrentStatePreview {
	isOpen: boolean
}

const initialState: ICurrentStatePreview = {
	isOpen: false,
}

export const IsOpenSlice = createSlice({
	name: 'stateDropDown',
	initialState,
	reducers: {
		setIsOpenSlice: (state, action: PayloadAction<any>) => {
			state.isOpen = action.payload
		},
	},
})

export const { setIsOpenSlice } = IsOpenSlice.actions

export const isOpenData = (state: RootState) => state.IsOpenSlice.isOpen

export default IsOpenSlice.reducer
