import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import JSZip from 'jszip'
import { useEffect, useRef, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useDispatch, useSelector } from 'react-redux'
import { selectPathNew } from '../../app/redux/slices/CurrentDirectoryNewSlice'
import { setCurrentPath } from '../../app/redux/slices/CurrentDirectorySlice'
import {
	fetchFolderCurrent,
	setCurrentFolder,
} from '../../app/redux/slices/currentFolderInfo'
import { setCurrentImagePath } from '../../app/redux/slices/CurrentImageSlice'
import { fetchDropDownSlice } from '../../app/redux/slices/DropDownSlice'
import { currentStateDropDown } from '../../app/redux/slices/DropDownV2StateSlice'
import { fetchGenerateLink } from '../../app/redux/slices/generateLinkSlice'
import { fetchDirectories } from '../../app/redux/slices/getAllDirectories'
import { fetchDirectoriesUnder } from '../../app/redux/slices/getAllDirectoriesUnder'
import {
	isOpenLeftData,
	setIsOpenLeftSlice,
} from '../../app/redux/slices/IsOpenLeftSlice'
import { performSequentialActions } from '../../app/redux/slices/performSequentialActionSlice'
import { prevDataLeftData } from '../../app/redux/slices/prevDataLeft/prevDataLeftSlice'
import { currentInfoPrevRightData } from '../../app/redux/slices/PrevRightDataSlice'
import { saverFolderData } from '../../app/redux/slices/SaveFolderDataSlice'
import {
	currentFirstSelectedPath,
	currentFirstSelectNameFullPath,
} from '../../app/redux/slices/stateSelect/FirstSelectedPathSlice'
import { AppDispatch } from '../../app/redux/store'
import BottomContextLeftV2 from '../../entities/BottomContextLeftV2/BottomContextLeftV2'
import { ContextMenuV2 } from '../../entities/ContextMenuV2/ContextMenuV2'
import { BuildPath } from '../../features/BuildPath'
import getFileExtension from '../../features/FindExten'
import { findExtenInExtens } from '../../features/findExtenValue'
import { downloadFile } from '../../features/SaveFile'
import { downloadZip } from '../../features/SaveFile/models/SaveFolder'
import splitText from '../../features/splitText'
import imgSuccess from '../../shared/assets/icons/check.svg'
import iconClose from '../../shared/assets/icons/Close.svg'
import imgCopy from '../../shared/assets/icons/Copy.svg'
import imgDownload from '../../shared/assets/icons/Download.svg'
import folderIcon from '../../shared/assets/icons/folder.svg'
import { arrIcons } from '../../shared/IconsAndExtensio'
import { FolderFaceNew, moveProps } from '../../shared/types/types'
import style from './ContextV2.module.scss'
const ContextLeftV2 = () => {
	const currentFolderInfo = useSelector(prevDataLeftData)
	const { path } = useSelector((state: any) => state.CurrentDirectorySlice)
	const { loadingInfo } = useSelector((state: any) => state.currentFolder)
	const { errorInfo } = useSelector((state: any) => state.currentFolder)
	const saverFolderDataLocal = useSelector(saverFolderData)
	const refBottom = useRef<any>(null)

	const dispatch = useDispatch<AppDispatch>()
	const { image } = useSelector((state: any) => state.CurrentImageSlice)
	const selectPathNewLocal = useSelector(selectPathNew)
	const currentInfoPrevRightDataLocal = useSelector(currentInfoPrevRightData)
	const { generateLink } = useSelector((state: any) => state.generateLinkSlice)
	const { currentInfoAboutMove, loadingInfoMove, errorInfoMove } = useSelector(
		(state: any) => state.fetchMoveSlice
	)
	useEffect(() => {
		if (
			currentInfoPrevRightDataLocal.size &&
			currentInfoPrevRightDataLocal.name &&
			currentInfoPrevRightDataLocal.type &&
			currentInfoPrevRightDataLocal.isLeft
		) {
			setIsOpenLeft(true)
			dispatch(setIsOpenLeftSlice(true))
		} else if (currentInfoPrevRightDataLocal.isLeft === false) {
			setIsOpenLeft(false)
			dispatch(setIsOpenLeftSlice(false))
		}
	}, [currentInfoPrevRightDataLocal])
	useEffect(() => {
		if (image) {
			dispatch(fetchGenerateLink(image))
		}
	}, [image])

	useEffect(() => {
		dispatch(fetchDirectoriesUnder())
		dispatch(fetchDropDownSlice())
		dispatch(fetchDirectories())
		dispatch(fetchFolderCurrent(path.join('/')))
	}, [])
	useEffect(() => {
		if (loadingInfo === false && errorInfo === null) {
			dispatch(setCurrentFolder(currentFolderInfo))
		}
	}, [currentFolderInfo, errorInfo, loadingInfo])

	const [isCopied, setIsCopied] = useState(false)
	const handleCopy = () => {
		setIsCopied(true)
		setTimeout(() => setIsCopied(false), 3000)
	}

	let [click, setCLick] = useState<number>(0)
	const PathForDelete = useSelector(
		(state: any) => state.CurrentDirectorySlice.path
	)
	useEffect(() => {
		if (click > 0 && PathForDelete.join('/').length > 0) {
			dispatch(fetchFolderCurrent(PathForDelete.join('/')))
			dispatch(setCurrentPath(PathForDelete.join('/')))
		}
	}, [click])

	let [contentsZero, setContentsZero] = useState<any>(null)
	const currentStateDropDownLocal = useSelector(currentStateDropDown)

	useEffect(() => {
		const ZeroObject: FolderFaceNew = {
			name: '...',
			type: 'directory',
			path: '',
			isHaveContent: false,
			index: 0,
			contents: [],
			size: 0,
		}

		if (currentFolderInfo) {
			const newContents = [ZeroObject, ...currentFolderInfo]
			setContentsZero(newContents)
		}
	}, [currentFolderInfo])
	useEffect(() => {
		const ZeroObject: FolderFaceNew = {
			name: '...',
			type: 'directory',
			path: '',
			isHaveContent: false,
			index: 0,
			contents: [],
			size: 0,
		}
		const newContents = currentFolderInfo
			? [ZeroObject, ...currentFolderInfo]
			: [ZeroObject]
		if (currentStateDropDownLocal) {
			if (currentStateDropDownLocal === 'all') {
				setContentsZero(newContents)
				return
			}
			const newElements = newContents.filter((element: any) => {
				if (element.type === currentStateDropDownLocal) {
					return element
				}
			})
			setContentsZero(newElements)
		}
	}, [currentStateDropDownLocal, currentFolderInfo, selectPathNewLocal])

	const [isContextMenu, setIsContextMenu] = useState<any>(false)
	const handleContextMenu = (event: React.MouseEvent) => {
		event.preventDefault()
		const targetElement = event.target as HTMLElement
		const elementId = targetElement.id

		if (elementId === 'out' || elementId.trim().length === 0) {
			setIsContextMenu(!isContextMenu)
		}
	}
	const handleContextMenuBack = () => {
		setIsContextMenu(false)
	}
	const [isOpenLeft, setIsOpenLeft] = useState<boolean>(false)
	const isOpenLeftDataLocal = useSelector(isOpenLeftData)
	const handleOpen = () => {
		setIsOpenLeft(!isOpenLeft)
		dispatch(setIsOpenLeftSlice(!isOpenLeftDataLocal))
		dispatch(setCurrentImagePath(''))
	}
	const setSrc = (): string => {
		let extenOne: null | string = null
		let typeOne: null | 'directory' = null
		const exten: string = getFileExtension(generateLink.link)
		if (exten.trim().length === 0) {
			extenOne = null
		} else if (exten.trim().length > 0) {
			extenOne = exten
		}

		const result: string = arrIcons(extenOne, typeOne)
		if (extenOne === 'jpg' || extenOne === 'png') {
			return generateLink.link
		}
		return result
	}

	function formatBytes(bytes: number): string {
		if (bytes < 1024) return `${bytes} Б`
		const units = ['Кб', 'Мб', 'Гб', 'Тб']
		let unitIndex = -1
		let size = bytes

		do {
			size /= 1024
			unitIndex++
		} while (size >= 1024 && unitIndex < units.length - 1)

		return `${size.toFixed(2)} ${units[unitIndex]}`
	}
	const zip = new JSZip()
	useEffect(() => {}, [currentFolderInfo])

	const currentFirstSelectedPathLocal = useSelector(currentFirstSelectedPath)
	const currentFirstSelectNameFullPathLocal = useSelector(
		currentFirstSelectNameFullPath
	)
	const handleDragOver = (event: React.DragEvent) => {
		event.preventDefault()
	}
	function trimLastSegment(str: string): string {
		const lastSlashIndex = str.lastIndexOf('/')
		// Если в строке нет '/' или она содержит только одно слово, возвращаем пустую строку
		return lastSlashIndex === -1 ? '' : str.substring(0, lastSlashIndex)
	}
	const PathOne = useSelector((state: any) => state.CurrentDirectorySlice)
	const currentLeftPathLocal = useSelector(
		(state: any) => state.CurrentDirectoryNewSlice
	)
	const dispatchThunk = useDispatch<ThunkDispatch<{}, {}, AnyAction>>()
	const handleDrop = (event: React.DragEvent, destination: any) => {
		event.preventDefault()

		const droppedItem = JSON.parse(event.dataTransfer.getData('text/plain'))

		const targetId = (event.target as HTMLElement).id

		if (targetId) {
			dispatchThunk(
				performSequentialActions({
					newPath: targetId,
					oldPath: droppedItem.path,
					PathOne,
					leftPath: currentLeftPathLocal.path.join('/'),
				})
			)
			return
		}
		if (destination.type === null) {
			// Если дропнутый элемент не директория

			dispatchThunk(
				performSequentialActions({
					newPath: trimLastSegment(currentLeftPathLocal.path.join('/')),
					oldPath: droppedItem.path,
					PathOne,
					leftPath: trimLastSegment(currentLeftPathLocal.path.join('/')),
				})
			)
			return
		}

		// Построение старого и нового пути, если папка существует
		const oldPath: any = currentFolderInfo
			? BuildPath(droppedItem.name, currentFolderInfo)
			: null
		let dropInFolder: string = destination.name
		let newPath: any = currentFolderInfo
			? BuildPath(dropInFolder, currentFolderInfo)
			: null

		const dataMove: moveProps = { oldPath: droppedItem.path, newPath }

		if (path.length === 0) {
			dispatchThunk(
				performSequentialActions({
					newPath,
					oldPath: droppedItem.path,
					PathOne,
					leftPath: currentLeftPathLocal.path.join('/'),
				})
			)
			return
		}
	}

	return (
		<div
			ref={refBottom}
			id='out'
			draggable
			onDragOver={handleDragOver}
			onDrop={event =>
				handleDrop(event, { name: '', path: path.join('/'), type: null })
			}
			onClick={handleContextMenuBack}
			onContextMenu={handleContextMenu}
			className={style.wrapperRoot}
		>
			<div className={style.border}></div>
			<div className={style.wrapperContext}>
				<div className={style.wrapperTitle}>
					<span className={style.title}>Папки</span>
				</div>

				<div className={style.wrapperContextHorizontal}>
					{contentsZero !== null &&
						contentsZero.map((element: FolderFaceNew, index: number) => {
							if (element) {
								return (
									<BottomContextLeftV2
										key={element.path}
										{...element}
										index={index}
									/>
								)
							} else {
								return <div>not found</div>
							}
						})}

					{isContextMenu && refBottom.current.id !== 'inner' && (
						<ContextMenuV2 path={path.join('/')} isContext={true} />
					)}
				</div>
			</div>
			<div
				onClick={() => {
					setIsContextMenu(false)
				}}
				className={isOpenLeft ? style.wrapperImg : style.closeImage}
			>
				{generateLink && currentInfoPrevRightDataLocal.type !== 'directory' && (
					<>
						<div className={style.titleImg}>
							<span>
								{splitText(currentInfoPrevRightDataLocal.name, 20)}
								{/* {splitText(name, 20)} */}
							</span>
							<button className={style.btnClose} onClick={handleOpen}>
								<img src={iconClose} alt='' />
							</button>
						</div>
						{isOpenLeft &&
							currentInfoPrevRightDataLocal.type !== 'directory' && (
								<div className={style.wrapperComponent}>
									<img className={style.openImage} src={setSrc()} alt='' />
									<div className={style.titleInfo}>
										<span>Информация</span>
									</div>
									<div className={style.wrapperInfo}>
										<div>
											<span>Размер</span>
											<span>
												{formatBytes(currentInfoPrevRightDataLocal.size)}
											</span>
										</div>
										<div>
											<span>Тип</span>
											<span>
												{findExtenInExtens(currentInfoPrevRightDataLocal.type)}
											</span>
										</div>
									</div>
									<button
										onClick={() => downloadFile(generateLink.link)}
										className={style.btnDownload}
									>
										<img
											className={style.imgDownload}
											src={imgDownload}
											alt='d'
										/>
										Скачать
									</button>
									<CopyToClipboard text={generateLink.link} onCopy={handleCopy}>
										<div
											className={isCopied ? style.nonCopied : style.clipboard}
										>
											<img
												className={style.imgCopy}
												src={isCopied ? imgSuccess : imgCopy}
												alt='copy'
											/>
											{isCopied ? (
												<span>Скопировано</span>
											) : (
												`${splitText(generateLink.link, 20)}`
											)}
										</div>
									</CopyToClipboard>
								</div>
							)}
					</>
				)}
				{isOpenLeft && currentInfoPrevRightDataLocal.type === 'directory' && (
					<>
						<div className={style.titleImg}>
							<span>
								{splitText(currentInfoPrevRightDataLocal.name, 20)}
								{/* {splitText(name, 20)} */}
							</span>
							<button className={style.btnClose} onClick={handleOpen}>
								<img src={iconClose} alt='' />
							</button>
						</div>
						{isOpenLeft &&
							currentInfoPrevRightDataLocal.type === 'directory' && (
								<div className={style.wrapperComponent}>
									<img className={style.openImage} src={folderIcon} alt='' />
									<div className={style.titleInfo}>
										<span>Информация</span>
									</div>
									<div className={style.wrapperInfo}>
										<div>
											<span>Размер</span>
											<span>
												{formatBytes(currentInfoPrevRightDataLocal.size)}
											</span>
										</div>
										<div>
											<span>Тип</span>
											<span>
												{findExtenInExtens(currentInfoPrevRightDataLocal.type)}
											</span>
										</div>
									</div>
									<button
										onClick={async () => {
											try {
												await downloadZip(
													saverFolderDataLocal,
													currentFirstSelectNameFullPathLocal,
													currentFirstSelectedPathLocal
												)
											} catch (error) {
												console.error(
													'An error occurred while creating the ZIP file:'
												)
											}
										}}
										className={style.btnDownload}
									>
										<img
											className={style.imgDownload}
											src={imgDownload}
											alt='d'
										/>
										Скачать
									</button>
								</div>
							)}
					</>
				)}
			</div>
			{isOpenLeft ? <div className={style.block}></div> : ''}
		</div>
	)
}

export default ContextLeftV2
