import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import { Provider } from 'react-redux';

import store from './app/redux/store'
import { AppRouter } from './app/router/appRouter';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
  <Provider store={store}>
  <AppRouter />
  </Provider>
</>
);

