import { useSelector } from 'react-redux'
import { prevDataLeftData } from '../../app/redux/slices/prevDataLeft/prevDataLeftSlice'
import { GoBack } from '../../features/GoBack'
import ButtonAdd from '../../widgets/ButtonAdd/ButtonAdd'
import ButtonView from '../../widgets/ButtonView/ButtonView'
import ContextLeftV2 from '../../widgets/ContextLeftV2/ContextV2'
import ContextV2 from '../../widgets/ContextV2/ContextV2'
import DropDownV2 from '../../widgets/DropDownV2/DropDownV2'
import PathV2 from '../../widgets/PathV2/PathV2'
import SearchV2 from '../../widgets/SearchV2/SearchV2'
import Sidebar from '../../widgets/Sidebar/Sidebar'
import styles from './Components.module.scss'
// import Alert from '../../shared/ui/Alert/Alert'
export default function Components() {
	const { path } = useSelector((state: any) => state.CurrentDirectorySlice)
	const directoryUnderSlice = useSelector(
		(state: any) => state.directoryUnderSlice
	)
	const { currentFolderInfo } = useSelector((state: any) => state.currentFolder)

	const { isOpen } = useSelector((state: any) => state.IsOpenSlice)
	const { isOpenLeft } = useSelector((state: any) => state.IsOpenLeftSlice)
	const currentFolderInfoLocal = useSelector(prevDataLeftData)

	return (
		<div className={styles.wrapperRootComponent}>
			<Sidebar />
			<div className={styles.wrapperContent}>
				<div
					className={
						isOpen || isOpenLeft
							? `${styles.wrapperTitleMin}`
							: `${styles.wrapperTitle}`
					}
				>
					<div className={styles.wrapperGoBack}>
						{currentFolderInfoLocal && (
							<GoBack {...directoryUnderSlice.directories} />
						)}
					</div>

					<div className={styles.wrapperPath}>
						<PathV2 path={path} />
					</div>
					<div
						className={
							isOpen
								? `${styles.wrapperBtnAddCenter}`
								: isOpenLeft
								? `${styles.wrapperBtnAdd}`
								: !isOpen && !isOpenLeft
								? `${styles.wrapperBtnAddDef}`
								: ``
						}
					>
						<ButtonAdd arg={path} />
					</div>
				</div>
				<div
					className={
						currentFolderInfoLocal
							? `${styles.wrapperInputsCenter}`
							: `${styles.wrapperInputs}`
					}
				>
					<div className={styles.wrapperSearch}>
						<SearchV2 path={path} directories={currentFolderInfo} />
						<DropDownV2 />
					</div>
					<div
						className={
							isOpen
								? `${styles.wrapperBtnView}`
								: isOpenLeft
								? `${styles.wrapperBtnOpen}`
								: `${styles.wrapperBtn}`
						}
					>
						<ButtonView view='' />
					</div>
				</div>
				<div className={styles.wrapperContext}>
					{currentFolderInfoLocal && <ContextLeftV2 />}
					{currentFolderInfoLocal ? (
						<div className={styles.contextMin}>
							<ContextV2 />
						</div>
					) : (
						<ContextV2 />
					)}
				</div>
				{/* <Alert text='Файл перемещен в корзину' onCancel={helloFunc}/> */}
			</div>
		</div>
	)
}
