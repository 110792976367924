import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
interface IStateGoBack {
	isVoid: boolean
}
const initialState: IStateGoBack = {
	isVoid: false,
}
export const isVoidSlice = createSlice({
	name: 'isVoid',
	initialState,
	reducers: {
		setIsVoid: (state, action) => {
			state.isVoid = action.payload
		},
	},
})

export const GoBackIsVoid = (state: RootState) => state.isVoidSlice.isVoid

export const { setIsVoid } = isVoidSlice.actions

export default isVoidSlice.reducer
