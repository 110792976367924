import imgDownload from '../../assets/icons/icoDownload.svg'
import imgEdit from '../../assets/icons/PenEdit.svg'
import imgTrash from '../../assets/icons/Trash.svg'
import { IModalOption } from '../../types/types'
import style from './ModalOption.module.scss'

const ModalOption: React.FC<IModalOption> = ({ option }) => {
	const images = [imgDownload, imgEdit, imgTrash]
	return (
		<div className={style.wrapperModalRoot}>
			<div className={style.wrapperModalOption}>
				{option.map((el, index) => (
					<button className={style.btn} onClick={el.optionFunc} key={el.name}>
						<img src={images[index]} alt='' />
						{el.name}
					</button>
				))}
			</div>
		</div>
	)
}

export default ModalOption
