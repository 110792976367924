import { FolderFace } from "../shared/types/types";

import {Contents} from '../shared/types/types'
interface FindToDeletePathProps {
    name: string;
    data: Contents[];
} 

export const FindToDeletePath = ({ name, data }: FindToDeletePathProps): string => {
    let findPath: string = "";

    data.forEach((element: Contents) => {
        if (element.name === name) {
            findPath = element.path;
        }
    });

    return findPath;
};
