import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { FolderFaceNew } from '../../../../shared/types/types'
import { RootState } from '../../store'

interface AllDirectoryState {
	prevDataLeft: FolderFaceNew[] | null
	loadingInfo: boolean
	errorInfo: string | null
}
const initialState: AllDirectoryState = {
	prevDataLeft: null,
	loadingInfo: false,
	errorInfo: null,
}
export const fetchFolderNewLeftThunk = createAsyncThunk(
	'directoryOneNewLeft/fetchFolderCurrentNewLeft',
	async (arg: string, { rejectWithValue }) => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_SERVER}/media/getFolder`,
				{ path: arg }
			)
			return response.data // Assuming this returns Directory[]
		} catch (errorInfo: any) {
			return rejectWithValue(errorInfo.message)
		}
	}
)
export const prevDataLeftSlice = createSlice({
	name: 'prevDataLeft',
	initialState,
	reducers: {
		setPrevDataLeft: (state, action) => {
			state.prevDataLeft = action.payload
		},
	},
	extraReducers: builder => {
		builder
			.addCase(fetchFolderNewLeftThunk.pending, state => {
				state.loadingInfo = true
				state.errorInfo = null
			})
			.addCase(
				fetchFolderNewLeftThunk.fulfilled,
				(state, action: PayloadAction<FolderFaceNew[]>) => {
					state.loadingInfo = false
					state.prevDataLeft = action.payload
				}
			)
			.addCase(
				fetchFolderNewLeftThunk.rejected,
				(state, action: PayloadAction<any>) => {
					state.loadingInfo = false
					state.errorInfo = action.payload
				}
			)
	},
})

export const prevDataLeftLoading = (state: RootState) =>
	state.prevDataLeftSlice.loadingInfo
export const prevDataLeftError = (state: RootState) =>
	state.prevDataLeftSlice.errorInfo

export const prevDataLeftData = (state: RootState) =>
	state.prevDataLeftSlice.prevDataLeft
export const { setPrevDataLeft } = prevDataLeftSlice.actions

export default prevDataLeftSlice.reducer
