import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { IUpload } from '../../../shared/types/types'
import { fetchFolderCurrent } from './currentFolderInfo'
import { fetchDirectories } from './getAllDirectories'
import { fetchDirectoriesUnder } from './getAllDirectoriesUnder'
import { uploadFileThunk } from './UploadFilesSlice'

export const UploadFileSequentialActions =
	(data: IUpload) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		try {
			await dispatch(uploadFileThunk(data)).unwrap()
			await dispatch(fetchDirectories()).unwrap()
			await dispatch(fetchFolderCurrent(data.webPath)).unwrap()
			await dispatch(fetchDirectoriesUnder()).unwrap()
		} catch (error) {
			console.error('Ошибка выполнения:', error)
		}
	}
