import { useRef, useState } from 'react'
import { IModal } from '../../types/types'
import style from './Modal.module.scss'

const Modal: React.FC<IModal> = ({
	title,
	arg,
	isOpen,
	callback,
	onCancel,
	valid,
}) => {
	const refModal = useRef<any>(null)
	const [text, setText] = useState<string>('')

	const handleName = (event: any) => {
		setText(event.target.value)
	}

	const handleCheckClose = (event: React.MouseEvent<HTMLDivElement>) => {
		if (refModal.current && !refModal.current.contains(event.target as Node)) {
			onCancel() // Закрываем окно
		}
	}
	return (
		<div onClick={handleCheckClose} className={style.wrapperModal}>
			<div ref={refModal} id='content' className={style.contentModal}>
				<span className={style.titleModal}>{title}</span>
				<input
					onChange={handleName}
					className={style.wrapperInput}
					type='text'
					placeholder='Введите название'
				/>
				<div className={style.wrapperBtns}>
					<button className={style.btnCancel} onClick={onCancel}>
						Отменить
					</button>
					<button
						onClick={() => {
							if (valid === text) {
								alert(
									'Нельзя создавать вложенную папку или файл с таким названием родительской папки'
								)
								return
							}
							callback(text)
						}}
						className={style.btnAdd}
					>
						{arg}
					</button>
				</div>
			</div>
		</div>
	)
}

export default Modal
