import axios from 'axios'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { FolderFace } from '../../../shared/types/types'

// Функция для запроса файла и его скачивания как Blob
async function fetchFile(path: string): Promise<any> {
	const response = await axios.get(
		`${process.env.REACT_APP_SERVER}/media/generateLink?webPath=${path}`
	)
	return response.data
}

// Функция для добавления файлов и папок в ZIP с учётом вложенности
// Функция для добавления файлов и папок в ZIP с учётом вложенности
export async function addFilesToZip(
	zip: JSZip,
	contents: FolderFace[],
	pathFull: string,
	relativePath: string = ''
) {
	for (const item of contents) {
		let fullPath = item.path.startsWith(pathFull)
			? item.path
			: `${pathFull}/${item.path}`

		fullPath = removeDuplicatePathSegments(fullPath)

		if (item.type === 'directory') {
			// Создаём папку в ZIP (даже если она пустая)
			const zipPath = fullPath.replace(new RegExp(`^${pathFull}/?`), '')
			zip.folder(zipPath) // Создаём пустую папку

			// Если есть содержимое, добавляем его рекурсивно
			if (item.contents) {
				await addFilesToZip(zip, item.contents, pathFull, fullPath)
			}
		} else {
			// Скачиваем файл и добавляем его в ZIP
			try {
				const fileBlob = await fetchFile(fullPath)
				const response = await axios.get(fileBlob.link, {
					responseType: 'blob',
				})

				const zipPath = fullPath.replace(new RegExp(`^${pathFull}/?`), '')
				zip.file(zipPath, response.data)
			} catch (error) {
				console.error(`Ошибка при загрузке файла ${item.name}:`, error)
			}
		}
	}
}

// Функция для удаления повторяющихся частей пути
function removeDuplicatePathSegments(fullPath: string): string {
	// Разбиваем путь на сегменты
	const pathParts = fullPath.split('/')

	// Создаём новый массив для хранения уникальных сегментов
	const seen = new Set<string>()
	const result: string[] = []

	// Проходим по сегментам и добавляем только уникальные
	for (const part of pathParts) {
		if (!seen.has(part)) {
			seen.add(part)
			result.push(part)
		}
	}

	// Собираем путь обратно из уникальных сегментов
	return result.join('/')
}

// Основная функция для создания и скачивания ZIP архива
export async function downloadZip(
	folder: FolderFace,
	pathFull: string,
	pathSelected: string
) {
	const zip = new JSZip()
	await addFilesToZip(zip, folder.contents || [], pathFull)

	// Генерация и сохранение ZIP файла
	const zipBlob = await zip.generateAsync({ type: 'blob' })
	saveAs(zipBlob, `${folder.name}.zip`)
}
