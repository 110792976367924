import './app/scss/index.scss'
import Components from './pages/Components/Components';
import { SearchPage } from './pages/SearchPage'
import Header from './widgets/Header/Header'

function App() {
  return (
    <div className="App">

      <Components/>
    </div>
  );
}

export default App;
