import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

interface ICurrentStateDropDown {
	dropdownState: null | string
}

const initialState: ICurrentStateDropDown = {
	dropdownState: null,
}

export const DropDownV2StateSlice = createSlice({
	name: 'stateDropDown',
	initialState,
	reducers: {
		setCurrentStateDropDown: (state, action: PayloadAction<any>) => {
			state.dropdownState = action.payload
		},
	},
})

export const { setCurrentStateDropDown } = DropDownV2StateSlice.actions

export const currentStateDropDown = (state: RootState) =>
	state.DropDownV2StateSlice.dropdownState

export default DropDownV2StateSlice.reducer
