import {  createSlice } from "@reduxjs/toolkit"
import { RootState } from '../store'
import { FolderFaceNew, IContext } from "../../../shared/types/types";

  interface AllDirectoryState {
    currentInfoPrevRight: FolderFaceNew; 

  }
  const initialState: AllDirectoryState = {
    currentInfoPrevRight: {
        name: 'test',
        type: 'directory',
        path: '',
        isHaveContent: false,
        index: 0,
        contents: [],
        size: 0,
         
    },

  };
  
  export const SaveFolderDataSlice = createSlice({
    name: 'prevDataFolder',
    initialState,
    reducers: {
      setSaverFolderData: (state, action) => {
        state.currentInfoPrevRight = action.payload;
      },
    },
  });
  
  export const saverFolderData = (state: RootState) => state.SaveFolderDataSlice.currentInfoPrevRight;
  export const {setSaverFolderData} = SaveFolderDataSlice.actions
  
  export default SaveFolderDataSlice.reducer;
  