// ModalSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ModalState {
	openModalId: string | null
}

const initialState: ModalState = {
	openModalId: null,
}

const modalSlice = createSlice({
	name: 'modal',
	initialState,
	reducers: {
		setOpenModalId: (state, action: PayloadAction<string | null>) => {
			state.openModalId = action.payload
		},
	},
})

export const { setOpenModalId } = modalSlice.actions
export default modalSlice.reducer
