import {  createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../store";
  interface IStateSelect {
    name: string; 
    selectedName: string

  }
  const initialState: IStateSelect = {
        name: "",
        selectedName: ""
  };
  export const FirstSelectedPathSlice = createSlice({
    name: 'dataStateFirstOne',
    initialState,
    reducers: {
      setSelectCurrentFirst: (state, action) => {
        state.name = action.payload
        const lastSegment = action.payload.split('/').pop();
        state.selectedName = lastSegment
      },
    },
  });
  
  export const currentFirstSelectNameFullPath = (state: RootState) => state.FirstSelectedPathSlice.name;
  export const currentFirstSelectedPath = (state: RootState) => state.FirstSelectedPathSlice.selectedName;
  export const {setSelectCurrentFirst} = FirstSelectedPathSlice.actions
  
  export default FirstSelectedPathSlice.reducer;
  