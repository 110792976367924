import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPathNew } from '../../../app/redux/slices/CurrentDirectoryNewSlice'
import { setCurrentPath } from '../../../app/redux/slices/CurrentDirectorySlice'
import { fetchFolderCurrent } from '../../../app/redux/slices/currentFolderInfo'
import { setDropDownData } from '../../../app/redux/slices/DropDownSlice'
import {
	GoBackCount,
	setCount,
} from '../../../app/redux/slices/GoBack/GoBackCountSlice'
import { GoBackIsVoid } from '../../../app/redux/slices/isVoid/isVoidSlice'
import { setPrevDataLeft } from '../../../app/redux/slices/prevDataLeft/prevDataLeftSlice'
import { AppDispatch } from '../../../app/redux/store'
import imgArr from '../../../shared/assets/icons/AltArrowRight.svg'
import { FolderFace } from '../../../shared/types/types'
import style from './GoBack.module.scss'

const GoBack: React.FC<FolderFace> = ({
	name,
	type,
	path,
	isHaveContent,
	contents,
}) => {
	const dispatch = useDispatch<AppDispatch>()
	const [click, setClick] = useState<number>(0)
	const PathOne = useSelector((state: any) => state.CurrentDirectorySlice)
	const selectPathNewLocal = useSelector(
		(state: any) => state.CurrentDirectoryNewSlice.path
	)
	const GoBackIsVoidLocal = useSelector(GoBackIsVoid)
	const GoBackCountLocal = useSelector(GoBackCount)
	const handleOnClick = () => {
		setClick(click + 1)
		dispatch(setCount(GoBackCountLocal + 1))
		//dispatch(setDeleteLastPath())
	}
	useEffect(() => {
		if (click > 0) {
			const newPath = selectPathNewLocal.slice(0, -1)
			dispatch(fetchFolderCurrent(PathOne.path.join('/')))
			dispatch(setCurrentPath(PathOne.path.slice(0, -1).join('/')))
			dispatch(setDropDownData(contents))
			dispatch(setCurrentPathNew(newPath.join('/')))

			// dispatch(setPrevDataLeft(null));
		}
	}, [click])

	useEffect(() => {
		if (PathOne.path.join('/').length === 0) {
			dispatch(setPrevDataLeft(null))
		}
	}, [PathOne])
	return (
		<div className={style.wrapperButtonBack} onClick={handleOnClick}>
			<img className={style.imgBack} src={imgArr} alt='' />
			Назад
		</div>
	)
}

export default GoBack
