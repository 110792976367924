import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

interface ICurrentStateDropDown {
	isList: boolean
}

const initialState: ICurrentStateDropDown = {
	isList: false,
}

export const IsListSlice = createSlice({
	name: 'stateDropDown',
	initialState,
	reducers: {
		setIsList: (state, action: PayloadAction<any>) => {
			state.isList = action.payload
		},
	},
})

export const { setIsList } = IsListSlice.actions

export const isListData = (state: RootState) => state.IsListSlice.isList

export default IsListSlice.reducer
