import { BrowserRouter, Route, Routes } from 'react-router-dom'
import App from '../../App'
import Components from '../../pages/Components/Components'
import { Fallback } from '../../shared/ui/fallback'
export const AppRouter = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path='*' element={<Fallback />} />
				<Route path='/' element={<App />} />
				{/* <Route path='/' element={<Components />} /> */}
			</Routes>
		</BrowserRouter>
	)
}
