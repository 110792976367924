import axios from 'axios'
import { saveAs } from 'file-saver'
import { v4 as uuidv4 } from 'uuid'
export const downloadFile = async (url: string): Promise<Blob> => {
	try {
		// Запрашиваем файл в виде Blob
		const response = await axios.get(url, {
			responseType: 'blob',
		})

		// Сохраняем файл с помощью file-saver
		saveAs(response.data, uuidv4())

		// Возвращаем Blob, если нужно использовать его далее
		return response.data
	} catch (error) {
		console.error('Ошибка при загрузке файла:', error)
		throw new Error('Не удалось загрузить файл. Попробуйте снова.')
	}
}
