import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
interface IStateGoBack {
	count: number
}
const initialState: IStateGoBack = {
	count: 0,
}
export const GoBackCountSlice = createSlice({
	name: 'goBackCount',
	initialState,
	reducers: {
		setCount: (state, action) => {
			state.count = action.payload
		},
	},
})

export const GoBackCount = (state: RootState) => state.GoBackCountSlice.count

export const { setCount } = GoBackCountSlice.actions

export default GoBackCountSlice.reducer
