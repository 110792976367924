import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import JSZip from 'jszip'
import { useEffect, useRef, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useDispatch, useSelector } from 'react-redux'
import {
	DataContext,
	setContextMenuData,
} from '../../app/redux/slices/ContextMenuSlice/ContextMenuSlice'
import { createFolderSeqActionSlice } from '../../app/redux/slices/createFolderSeqActionSlice'
import { setCurrentPath } from '../../app/redux/slices/CurrentDirectorySlice'
import {
	fetchFolderCurrent,
	setCurrentFolder,
} from '../../app/redux/slices/currentFolderInfo'
import { setCurrentImagePath } from '../../app/redux/slices/CurrentImageSlice'
import { fetchDropDownSlice } from '../../app/redux/slices/DropDownSlice'
import { currentStateDropDown } from '../../app/redux/slices/DropDownV2StateSlice'
import { fetchGenerateLink } from '../../app/redux/slices/generateLinkSlice'
import { fetchDirectories } from '../../app/redux/slices/getAllDirectories'
import { fetchDirectoriesUnder } from '../../app/redux/slices/getAllDirectoriesUnder'

import { isOpenLeftData } from '../../app/redux/slices/IsOpenLeftSlice'
import { isOpenData, setIsOpenSlice } from '../../app/redux/slices/IsOpenSlice'
import { performSequentialActions } from '../../app/redux/slices/performSequentialActionSlice'
import { currentInfoPrevRightData } from '../../app/redux/slices/PrevRightDataSlice'
import { saverFolderData } from '../../app/redux/slices/SaveFolderDataSlice'
import {
	currentFirstSelectedPath,
	currentFirstSelectNameFullPath,
} from '../../app/redux/slices/stateSelect/FirstSelectedPathSlice'
import { UploadFileSequentialActions } from '../../app/redux/slices/UploadFileSequentialActionSlice'
import { AppDispatch } from '../../app/redux/store'
import BottomContextV2 from '../../entities/BottomContextV2/BottomContextV2'
import { ContextMenuV2 } from '../../entities/ContextMenuV2/ContextMenuV2'
import { BuildPath } from '../../features/BuildPath'
import getFileExtension from '../../features/FindExten'
import { findExtenInExtens } from '../../features/findExtenValue'
import { downloadFile } from '../../features/SaveFile'
import { downloadZip } from '../../features/SaveFile/models/SaveFolder'
import splitText from '../../features/splitText'
import imgSuccess from '../../shared/assets/icons/check.svg'
import iconClose from '../../shared/assets/icons/Close.svg'
import imgCopy from '../../shared/assets/icons/Copy.svg'
import imgDownload from '../../shared/assets/icons/Download.svg'
import folderIcon from '../../shared/assets/icons/folder.svg'
import { arrIcons } from '../../shared/IconsAndExtensio'
import { FolderFaceNew, moveProps } from '../../shared/types/types'
import Modal from '../../shared/ui/Modal/Modal'
import style from './ContextV2.module.scss'
const ContextV2 = () => {
	const dispatchThunk = useDispatch<ThunkDispatch<{}, {}, AnyAction>>()
	const modalContainerRef = useRef<HTMLDivElement>(null)
	const PathOne = useSelector((state: any) => state.CurrentDirectorySlice)
	const { path } = useSelector((state: any) => state.CurrentDirectorySlice)
	const handleOpenModal = () => {
		setIsOpen(true)
	}

	const handleClose = () => {
		dispatch(
			setContextMenuData({
				isCreate: false,
				isUploadFile: false,
				isUploadDirectory: false,
			})
		)
	}

	const helloFunc = (nameFile: string) => {
		dispatchThunk(
			createFolderSeqActionSlice({ path: path.join('/'), folderName: nameFile })
		)
		handleClose()
	}
	const [isOpen, setIsOpen] = useState<boolean>(false)

	// Закрытие при клике вне модального окна и при нажатии Esc
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				modalContainerRef.current &&
				!modalContainerRef.current.contains(event.target as Node)
			) {
				handleClose()
			}
		}

		const handleEscPress = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				handleClose()
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		document.addEventListener('keydown', handleEscPress)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
			document.removeEventListener('keydown', handleEscPress)
		}
	}, [])

	const { directories, loading, error } = useSelector(
		(state: any) => state.directory
	)
	const directoryUnderSlice = useSelector(
		(state: any) => state.directoryUnderSlice
	)
	const { currentFolderInfo } = useSelector((state: any) => state.currentFolder)
	const { loadingInfo } = useSelector((state: any) => state.currentFolder)
	const { errorInfo } = useSelector((state: any) => state.currentFolder)
	const { isList } = useSelector((state: any) => state.IsListSlice)
	const saverFolderDataLocal = useSelector(saverFolderData)
	const refBottom = useRef<any>(null)

	const dispatch = useDispatch<AppDispatch>()
	const { image } = useSelector((state: any) => state.CurrentImageSlice)

	const currentInfoPrevRightDataLocal = useSelector(currentInfoPrevRightData)
	const { generateLink } = useSelector((state: any) => state.generateLinkSlice)
	useEffect(() => {
		if (
			currentInfoPrevRightDataLocal.size &&
			currentInfoPrevRightDataLocal.name &&
			currentInfoPrevRightDataLocal.type &&
			!currentInfoPrevRightDataLocal.isLeft
		) {
			setIsOpen(true)
			dispatch(setIsOpenSlice(true))
		} else if (currentInfoPrevRightDataLocal.isLeft === true) {
			setIsOpen(false)
			dispatch(setIsOpenSlice(false))
		}
	}, [currentInfoPrevRightDataLocal])
	useEffect(() => {
		if (image) {
			dispatch(fetchGenerateLink(image))
		}
	}, [image])

	useEffect(() => {
		dispatch(fetchDirectoriesUnder())
		dispatch(fetchDropDownSlice())
		dispatch(fetchDirectories())
		dispatch(fetchFolderCurrent(path.join('/')))
	}, [])
	useEffect(() => {
		if (loadingInfo === false && errorInfo === null) {
			dispatch(setCurrentFolder(currentFolderInfo))
		}
	}, [currentFolderInfo, errorInfo, loadingInfo])

	const [isCopied, setIsCopied] = useState(false)
	const handleCopy = () => {
		setIsCopied(true)
		setTimeout(() => setIsCopied(false), 3000)
	}

	let [click, setCLick] = useState<number>(0)
	const PathForDelete = useSelector(
		(state: any) => state.CurrentDirectorySlice.path
	)
	useEffect(() => {
		if (click > 0 && PathForDelete.join('/').length > 0) {
			dispatch(fetchFolderCurrent(PathForDelete.join('/')))
			dispatch(setCurrentPath(PathForDelete.join('/')))
		}
	}, [click])

	let [contentsZero, setContentsZero] = useState<any>(null)
	const currentStateDropDownLocal = useSelector(currentStateDropDown)

	useEffect(() => {
		const ZeroObject: FolderFaceNew = {
			name: '...',
			type: 'directory',
			path: '',
			isHaveContent: false,
			index: 0,
			contents: [],
			size: 0,
		}

		if (currentFolderInfo) {
			const newContents = [...currentFolderInfo]
			setContentsZero(newContents)
		}
	}, [currentFolderInfo])
	useEffect(() => {
		const ZeroObject: FolderFaceNew = {
			name: '...',
			type: 'directory',
			path: '',
			isHaveContent: false,
			index: 0,
			contents: [],
			size: 0,
		}
		if (currentFolderInfo) {
			const newContents = [...currentFolderInfo]
			if (currentStateDropDownLocal) {
				if (currentStateDropDownLocal === 'all') {
					setContentsZero(newContents)
					return
				}
				const newElements = newContents.filter((element: any) => {
					if (element.type === currentStateDropDownLocal) {
						return element
					}
				})
				setContentsZero(newElements)
			}
		}
	}, [currentStateDropDownLocal, currentFolderInfo])

	const [isContextMenu, setIsContextMenu] = useState<any>(false)
	const handleContextMenu = (event: React.MouseEvent) => {
		event.preventDefault()
		const targetElement = event.target as HTMLElement
		const elementId = targetElement.id

		if (elementId === 'out' || elementId.trim().length === 0) {
			setIsContextMenu(!isContextMenu)
		}
	}
	const handleContextMenuBack = () => {
		setIsContextMenu(false)
	}
	const isOpenDataLocal = useSelector(isOpenData)
	const handleOpen = () => {
		setIsOpen(false)
		dispatch(setIsOpenSlice(false))
		dispatch(setCurrentImagePath(''))
	}
	const setSrc = (): string => {
		let extenOne: null | string = null
		let typeOne: null | 'directory' = null
		const exten: string = getFileExtension(generateLink.link)
		if (exten.trim().length === 0) {
			extenOne = null
		} else if (exten.trim().length > 0) {
			extenOne = exten
		}

		const result: string = arrIcons(extenOne, typeOne)
		if (extenOne === 'jpg' || extenOne === 'png') {
			return generateLink.link
		}
		return result
	}

	function formatBytes(bytes: number): string {
		if (bytes < 1024) return `${bytes} Б`
		const units = ['Кб', 'Мб', 'Гб', 'Тб']
		let unitIndex = -1
		let size = bytes

		do {
			size /= 1024
			unitIndex++
		} while (size >= 1024 && unitIndex < units.length - 1)

		return `${size.toFixed(2)} ${units[unitIndex]}`
	}
	const zip = new JSZip()
	useEffect(() => {}, [currentFolderInfo])

	const currentFirstSelectedPathLocal = useSelector(currentFirstSelectedPath)
	const currentFirstSelectNameFullPathLocal = useSelector(
		currentFirstSelectNameFullPath
	)
	const DataContextLocal = useSelector(DataContext)
	const fileInputRef = useRef<HTMLInputElement | null>(null)

	// Функция для вызова окна выбора файла
	useEffect(() => {
		if (DataContextLocal.isUploadFile) {
			fileInputRef.current?.click()
		}
	}, [DataContextLocal])

	// Обработчик выбора файла

	const [FileState, setFileState] = useState<File | null>(null)
	//const dispatch = useDispatch<AppDispatch>()
	// Обработчик выбора файла и его загрузки
	const handleFileChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		event.preventDefault()

		try {
			const File = event.target.files?.[0]

			if (File) {
				setFileState(File)
				dispatch(
					setContextMenuData({
						isCreate: false,
						isUploadFile: false,
						isUploadDirectory: false,
					})
				)
				// Обработка файла
			}
		} catch (error) {
			console.error('Error while selecting file:', error)
		}
	}

	useEffect(() => {
		if (FileState && path) {
			dispatchThunk(
				UploadFileSequentialActions({
					File: FileState,
					webPath: path.join('/'),
				})
			)
		}
	}, [FileState])
	function trimLastSegment(str: string): string {
		const lastSlashIndex = str.lastIndexOf('/')
		// Если в строке нет '/' или она содержит только одно слово, возвращаем пустую строку
		return lastSlashIndex === -1 ? '' : str.substring(0, lastSlashIndex)
	}
	const currentLeftPathLocal = useSelector(
		(state: any) => state.CurrentDirectoryNewSlice
	)
	const isOpenLeftDataLocal = useSelector(isOpenLeftData)
	const handleDragOver = (event: React.DragEvent) => {
		event.preventDefault()
	}
	const { currentInfoAboutMove, loadingInfoMove, errorInfoMove } = useSelector(
		(state: any) => state.fetchMoveSlice
	)
	const handleDrop = (event: React.DragEvent, destination: any) => {
		event.preventDefault()

		const droppedItem = JSON.parse(event.dataTransfer.getData('text/plain'))

		let newPathLocal = destination.path.substring(
			0,
			destination.path.lastIndexOf('/')
		)
		if (PathOne.path.join('/') === droppedItem.path) {
			return
		}
		const targetId = (event.target as HTMLElement).id

		if (targetId) {
			dispatchThunk(
				performSequentialActions({
					newPath: targetId,
					oldPath: droppedItem.path,
					PathOne,
					leftPath: currentLeftPathLocal.path.join('/'),
				})
			)
			return
		}

		if (destination.type === 'directory') {
			let newPath = destination.path + '/' + droppedItem.name
			let oldPath = droppedItem.path

			if (newPath === oldPath) {
				return
			}
			dispatchThunk(
				performSequentialActions({
					newPath: destination.path,
					oldPath: droppedItem.path,
					PathOne,
					leftPath: currentLeftPathLocal.path.join('/'),
				})
			)
		} else if (!destination.type) {
			dispatchThunk(
				performSequentialActions({
					newPath: path.join('/'),
					oldPath: droppedItem.path,
					PathOne,
					leftPath: trimLastSegment(currentLeftPathLocal.path.join('/')),
				})
			)
			return
		}

		// Построение старого и нового пути, если папка существует
		const oldPath: any = currentFolderInfo
			? BuildPath(droppedItem.name, currentFolderInfo)
			: null
		let dropInFolder: string = destination.name
		let newPath: any = currentFolderInfo
			? BuildPath(dropInFolder, currentFolderInfo)
			: null

		const dataMove: moveProps = { oldPath: droppedItem.path, newPath }

		if (path.length === 0) {
			dispatchThunk(
				performSequentialActions({
					newPath,
					oldPath: droppedItem.path,
					PathOne,
					leftPath: currentLeftPathLocal.path.join('/'),
				})
			)
			return
		}

		// // Проверка состояния загрузки перед выполнением перемещения
		// if (loadingInfoMove === false && errorInfoMove === null) {
		// 	dispatchThunk(
		// 		performSequentialActions({
		// 			newPath,
		// 			oldPath: droppedItem.path,
		// 			PathOne,
		// 			leftPath: currentLeftPathLocal.path.join('/'),
		// 		})
		// 	)
		// }
	}

	return (
		<div
			onClick={handleContextMenuBack}
			onContextMenu={handleContextMenu}
			className={style.wrapperRoot}
		>
			<div
				draggable
				onDragOver={handleDragOver}
				onDrop={event =>
					handleDrop(event, { name: '', path: path.join('/'), type: null })
				}
				className={
					isOpenLeftDataLocal
						? `${style.wrapperContextMin}`
						: `${style.wrapperContext}`
				}
			>
				<div className={isList ? style.wrapperTitleNone : style.wrapperTitle}>
					<span className={style.title}>Название</span>
					<span className={style.title}>Размер файла</span>
					<span className={style.title}>Тип</span>
				</div>

				<div
					ref={refBottom}
					id='out'
					onClick={handleContextMenuBack}
					className={
						isList ? style.wrapperContextBlocks : style.wrapperContextHorizontal
					}
				>
					{contentsZero !== null &&
						contentsZero.map((element: FolderFaceNew, index: number) => {
							if (element) {
								return (
									<BottomContextV2
										key={element.path}
										{...element}
										index={index}
									/>
								)
							} else {
								return <div>not found</div>
							}
						})}

					{isContextMenu && refBottom.current.id !== path.join('/') && (
						<ContextMenuV2 path={path.join('/')} isContext={true} />
					)}
				</div>
			</div>
			<div
				onClick={() => {
					setIsContextMenu(false)
				}}
				className={isOpen ? style.wrapperImg : style.closeImage}
			>
				{generateLink && currentInfoPrevRightDataLocal.type !== 'directory' && (
					<>
						<div className={style.titleImg}>
							<span>
								{splitText(currentInfoPrevRightDataLocal.name, 20)}
								{/* {splitText(name, 20)} */}
							</span>
							<button className={style.btnClose} onClick={handleOpen}>
								<img src={iconClose} alt='' />
							</button>
						</div>
						{isOpen && currentInfoPrevRightDataLocal.type !== 'directory' && (
							<div className={style.wrapperComponent}>
								<img className={style.openImage} src={setSrc()} alt='' />
								<div className={style.titleInfo}>
									<span>Информация</span>
								</div>
								<div className={style.wrapperInfo}>
									<div>
										<span>Размер</span>
										<span>
											{formatBytes(currentInfoPrevRightDataLocal.size)}

											{/* {formatBytes(size)} */}
										</span>
									</div>
									<div>
										<span>Тип</span>
										<span>
											{findExtenInExtens(currentInfoPrevRightDataLocal.type)}
										</span>
									</div>
								</div>
								<button
									onClick={() => downloadFile(generateLink.link)}
									className={style.btnDownload}
								>
									<img
										className={style.imgDownload}
										src={imgDownload}
										alt='d'
									/>
									Скачать
								</button>
								<CopyToClipboard text={generateLink.link} onCopy={handleCopy}>
									<div className={isCopied ? style.nonCopied : style.clipboard}>
										<img
											className={style.imgCopy}
											src={isCopied ? imgSuccess : imgCopy}
											alt='copy'
										/>
										{isCopied ? (
											<span>Скопировано</span>
										) : (
											`${splitText(generateLink.link, 20)}`
										)}
									</div>
								</CopyToClipboard>
							</div>
						)}
					</>
				)}
				{isOpen && currentInfoPrevRightDataLocal.type === 'directory' && (
					<>
						<div className={style.titleImg}>
							<span>
								{splitText(currentInfoPrevRightDataLocal.name, 20)}
								{/* {splitText(name, 20)} */}
							</span>
							<button className={style.btnClose} onClick={handleOpen}>
								<img src={iconClose} alt='' />
							</button>
						</div>
						{isOpen && currentInfoPrevRightDataLocal.type === 'directory' && (
							<div className={style.wrapperComponent}>
								<img className={style.openImage} src={folderIcon} alt='' />
								<div className={style.titleInfo}>
									<span>Информация</span>
								</div>
								<div className={style.wrapperInfo}>
									<div>
										<span>Размер</span>
										<span>
											{formatBytes(currentInfoPrevRightDataLocal.size)}

											{/* {formatBytes(size)} */}
										</span>
									</div>
									<div>
										<span>Тип</span>
										<span>
											{findExtenInExtens(currentInfoPrevRightDataLocal.type)}
										</span>
									</div>
								</div>
								<button
									onClick={async () => {
										try {
											await downloadZip(
												saverFolderDataLocal,
												currentFirstSelectNameFullPathLocal,
												currentFirstSelectedPathLocal
											)
											// Start with the root folder and add its contents to the zip
										} catch (error) {
											console.error(
												'An error occurred while creating the ZIP file:',
												error
											)
										}
									}}
									className={style.btnDownload}
								>
									<img
										className={style.imgDownload}
										src={imgDownload}
										alt='d'
									/>
									Скачать
								</button>
							</div>
						)}
					</>
				)}
			</div>
			{DataContextLocal.isCreate && (
				<div className={style.modalBackdrop}>
					<div ref={modalContainerRef} className={style.wrapperModalWin}>
						<Modal
							title='Новая папка'
							arg='Добавить'
							isOpen={DataContextLocal.isCreate}
							callback={helloFunc}
							valid={PathOne.path.join('/').split('/').pop()}
							onCancel={handleClose} // Передаем функцию handleClose для кнопки Отмена
						/>
					</div>
				</div>
			)}
			<input
				type='file'
				ref={fileInputRef}
				onChange={handleFileChange}
				style={{ display: 'none' }} // Скрываем input
			/>
			{isOpen ? <div className={style.block}></div> : ''}
			{/* {isContextMenu && refBottom.current.id === 'inner' && (
				<ContextMenuV2 path={path} isContext={true} />
			)} */}
		</div>
	)
}

export default ContextV2
