import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
	fetchFolderCurrent,
	setCurrentFolder,
} from '../../app/redux/slices/currentFolderInfo'
import { fetchDirectories } from '../../app/redux/slices/getAllDirectories'
import { AppDispatch } from '../../app/redux/store'
import iconSearch from '../../shared/assets/icons/search.svg'
import style from './SearchV2.module.scss'
export default function SearchV2({ directories, path }: any) {
	const dispatch = useDispatch<AppDispatch>()
	useEffect(() => {
		dispatch(fetchDirectories())
	}, [])
	const onChangeFolder = (e: any) => {
		let some = e.target.value.toLowerCase()
		if (some.trim().length === 0) {
			dispatch(fetchFolderCurrent(path.join('/')))
		}
		if (directories.length > 0) {
			const result = directories.filter((el: any) =>
				el.name.toLowerCase().includes(some)
			)
			dispatch(setCurrentFolder(result))
		}
	}
	return (
		<div className={style.wrapperSearch}>
			<div className={style.blockSearch}>
				<img src={iconSearch} alt='' />
				<input onChange={onChangeFolder} type='text' placeholder='Поиск' />
			</div>
		</div>
	)
}
