import txt from '../shared/assets/icons/selectCheck.svg'
import folder from '../shared/assets/icons/folder.svg'
import docs from '../shared/google-docs.svg'
import html from '../shared/html_8242997.png'
import css from '../shared/css_8242982.png'
import php from '../shared/php_8243030.png'
import mp4 from '../shared/assets/icons/mp4-file.svg'
import js from '../shared/file_16425504.png'
import jpg from '../shared/assets/icons/jpg-file.svg'
import png from '../shared/assets/icons/png-file.svg'
import webp from '../shared/assets/icons/file_webp.svg'
import doc from '../shared/doc_8242986.png'
interface Icon {
    extension: string | null,
    icon: any,
    type: 'directory' | null
}
export const arrIcons = (extension:string|null, type:null|'directory'):string => {
    const Icons:Icon[] = [
        {
            extension: "txt",
            icon: txt,
            type: null, 
        },
        {
            extension: "html",
            icon: html,
            type: null,
        },
        {
            extension: "css",
            icon: css,
            type: null,
        },
        {
            extension: "jpg",
            icon: jpg,
            type: null,
        },
        {
            extension: "jpeg",
            icon: jpg,
            type: null,
        },
        {
            extension: "png",
            icon: png,
            type: null,
        },
        {
            extension: "webp",
            icon: webp,
            type: null,
        },
        {
            extension: "php",
            icon: php,
            type: null,
        },
        {
            extension: "mp4",
            icon: mp4,
            type: null,
        },
        {
            extension: "js",
            icon: js,
            type: null,
        },
        {
            extension: null,
            icon: folder,
            type: 'directory'
        },
        {
            extension: null,
            icon: doc,
            type: null,
        }
    ]
    let findedIcon:string=doc;
    Icons.filter((element:Icon)=>{
        if(type === element.type && element.extension === extension) {
            return findedIcon = element.icon
        } 

    })
    return findedIcon

}