import { FolderFaceNew } from '../shared/types/types'

export const BuildPathLeft = (
	last: string,
	allPath: FolderFaceNew[]
): string | null => {
	for (const item of allPath) {
		if (item.name === last) {
			return item.path
		} else if (item.contents) {
			const contentsWithIndex = item.contents.map((contentItem, idx) => ({
				...contentItem,
				index: idx,
			}))
			const result = BuildPathLeft(last, contentsWithIndex)
			if (result) {
				return result
			}
		}
	}
	return null
}
