import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
interface IContextMenu {
	isCreate: boolean
	isUploadFile: boolean
	isUploadDirectory: boolean
}
const initialState: IContextMenu = {
	isCreate: false,
	isUploadFile: false,
	isUploadDirectory: false,
}
export const ContextMenuSlice = createSlice({
	name: 'ContextMenuSlice',
	initialState,
	reducers: {
		setContextMenuData: (state, action) => {
			state.isCreate = action.payload.isCreate
			state.isUploadDirectory = action.payload.isUploadDirectory
			state.isUploadFile = action.payload.isUploadFile
		},
	},
})

export const DataContext = (state: RootState) => state.ContextMenuSlice

export const isCreateDataContext = (state: RootState) =>
	state.ContextMenuSlice.isCreate

export const isUploadDirectoryDataContext = (state: RootState) =>
	state.ContextMenuSlice.isUploadDirectory
export const isUploadFileDataContext = (state: RootState) =>
	state.ContextMenuSlice.isUploadFile

export const { setContextMenuData } = ContextMenuSlice.actions

export default ContextMenuSlice.reducer
