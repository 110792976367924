import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	currentStateDropDown,
	setCurrentStateDropDown,
} from '../../app/redux/slices/DropDownV2StateSlice'
import { AppDispatch } from '../../app/redux/store'
import splitText from '../../features/splitText'
import arrow from '../../shared/assets/icons/Arrow.svg'
import check from '../../shared/assets/icons/selectCheck.svg'
import { DropDownTypes } from '../../shared/types/types'
import style from './DropDownV2.module.scss'

const DropDownV2 = () => {
	const [isOpen, setIsOpen] = useState<boolean>(true)
	const [currentType, setCurrentType] = useState<string>('')
	const dispatch = useDispatch<AppDispatch>()
	const currentStateDropDownLocal = useSelector(currentStateDropDown)
	const types: DropDownTypes[] = [
		{ name: 'Все', value: 'all' },
		{ name: 'Папки', value: 'directory' },
		{ name: 'JPG', value: '.jpg' },
		{ name: 'MP4', value: '.mp4' },
		{ name: 'TXT', value: '.txt' },
		{ name: 'WEBP', value: '.webp' },
		{ name: 'PNG', value: '.png' },
	]
	const handleOpenSwitcher = () => {
		setIsOpen(!isOpen)
	}
	const handleClick = (name: string, value: string) => {
		setCurrentType(name)
		if (currentStateDropDownLocal === value) {
			dispatch(setCurrentStateDropDown('all'))
			setCurrentType('')
			setIsOpen(false)
			return
		}
		dispatch(setCurrentStateDropDown(value))
		setIsOpen(!isOpen)
	}

	return (
		<div className={style.wrapperRoot}>
			<button
				className={!isOpen ? style.switcherButtonActive : style.switcherButton}
				onClick={handleOpenSwitcher}
			>
				<div>
					<span>
						{currentType.trim().length === 0
							? 'Тип'
							: splitText(currentType, 15)}
					</span>
				</div>
				<img
					src={arrow}
					alt=''
					className={!isOpen ? style.imgArrowActive : style.imgArrow}
				/>
			</button>
			<ul
				className={
					!isOpen ? style.listDropDownEnable : style.listDropDownDisabled
				}
			>
				{types.map((element: DropDownTypes) => {
					return (
						<li
							className={
								currentType === element.name ? style.active : style.liDropDown
							}
							key={element.name}
						>
							<button
								className={style.btnLiDropDown}
								onClick={() => handleClick(element.name, element.value)}
							>
								{splitText(element.name, 14)}
								<img
									src={check}
									className={
										currentType === element.name
											? style.imgCheck
											: style.imgNonCheck
									}
									alt=''
								/>
							</button>
						</li>
					)
				})}
			</ul>
		</div>
	)
}
export default DropDownV2
