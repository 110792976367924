import { createSlice } from '@reduxjs/toolkit'
import { IContext } from '../../../shared/types/types'
import { RootState } from '../store'

interface AllDirectoryState {
	currentInfoPrevRight: IContext
}
const initialState: AllDirectoryState = {
	currentInfoPrevRight: {
		name: '',
		type: '',
		size: 0,
		isLeft: false,
	},
}

export const PrevRightDataSlice = createSlice({
	name: 'prevData',
	initialState,
	reducers: {
		setCurrentPrevRightData: (state, action) => {
			state.currentInfoPrevRight = action.payload
		},
	},
})

export const currentInfoPrevRightData = (state: RootState) =>
	state.PrevRightDataSlice.currentInfoPrevRight
export const { setCurrentPrevRightData } = PrevRightDataSlice.actions

export default PrevRightDataSlice.reducer
